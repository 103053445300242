$(function () {
    $("#btn-order-confirmation").on("click", function () {
        let orderCode = $(this).attr("data-order");
        let header = "===== order confirmation =====";
        let message = `
${header}

order *#${orderCode}*
date: ${new Date().toLocaleString()}

${Array(header.length).fill("=").join("")}
`;
        if (window.confirm("confirmation via whatsapp ?")) window.open(`https://wa.me/${window.whatsapp_number}?text=${encodeURIComponent(message)}`);
    });

    $("#btn-ask-your-order").on("click", function () {
        let orderCode = $(this).attr("data-order");
        let customer = $(this).attr("data-customer");
        let message = `
order *#${orderCode}*
customer: *${customer}*
date: ${new Date().toLocaleString()}
message:
${Array(header.length).fill("=").join("")}
`;
        if (window.confirm("open on whatsapp ?")) window.open(`https://wa.me/${window.whatsapp_number}?text=${encodeURIComponent(message)}`);
    });

    $("#btn-order-checkout").on("click", function () {
        let order = $(this).attr("data-order");
        let token = $(this).attr("data-token");

        $.ajax({
            url: "/api/orders/tokenizer",
            method: "POST",
            headers: {
                "X-API-TOKEN": token,
            },
            data: {
                order: order,
            },
            success: function (response) {
                if (!window.snap) throw new Error("snap.js doesn't included yet !");

                snap.pay(response.token, {
                    onSuccess: function (result) {
                        window.location.reload();
                    },
                    onPending: function (result) {
                        console.log("payment is pending", { result });
                    },
                    onError: function (err) {
                        console.log({ err });
                    },
                });
            },
        });
    });
});
