import { gsap } from "gsap";

window.gsap = gsap
window.$ = Zepto;

import "./config";
import "./animates";
import "./functionals/navigation";
import "./functionals/dropdown";
import "./functionals/carousel";
import "./functionals/accordion";
import "./functionals/search-field";
import "./functionals/order-page";

$(async function () {
    const WOW = (await import("wowjs")).default;
    const { setTheme, delay } = await import("./utils/functions");

    // const theme = localStorage.getItem("theme") || "light";
    const pathname = window.location.pathname;

    // setTheme(theme);

    setTimeout(() => {
        $(".alert").remove();
    }, 2000);

    // lazy load image
    $("img").each(async function () {
        // Unduh gambar menggunakan fetch
        const imageUrl = $(this).attr("data-src");

        try {
            if (!imageUrl) return;

            $(this).css({
                backgroundColor: "#eee",
            });

            $(this).attr("loading", "lazy");
            $(this).attr("src", imageUrl);

            // give a wait for next image
            await delay(100);

            console.log(`image sucessfully loaded!`);
        } catch (error) {
            console.log(`[error] ${imageUrl}: `, error);
        }
    });


    $("img").on("load", function () {
        $(this).css({
            background: "transparent",
        });
    })

    $("#theme-toggler #toggler").on("click", function () {
        let $icon = $(this).find("i");

        setTheme($icon.hasClass("fa-sun") ? "dark" : "light");

        $icon.toggleClass("fa-sun");
        $icon.toggleClass("fa-moon");
    });

    $("#rating-star-choice button").on("click", function () {
        let value = $(this).attr("data-value");

        $("#star").attr("value", value);

        $("#rating-star-choice button").removeClass("bg-secondary");
        ["bg-sublight", "dark:bg-subdark"].forEach((item) => $("#rating-star-choice button").addClass(item));
        ["bg-sublight", "dark:bg-subdark"].forEach((item) => $(this).removeClass(item));

        $(this).addClass("bg-secondary");
    });

    $(`button[type="submit"]`).on("click", function () {
        $(this).css({
            display: "flex",
            alignItems: "center",
            gap: "0.5rem",
        });
        $(this).attr("disabled", true);
        $(this).html(`
            <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
            ${$(this).text()}
        `);
    });

    for (let path of ["blogs", "pricings", "products"]) {
        if (pathname.split("/").indexOf(path) > -1) {
            let parameters = new URLSearchParams({
                action: "increase-visitor",
            });

            let response = await fetch(`${window.location.href}?${parameters}`, {
                method: "GET",
                headers: {
                    token: $("meta[name='X-API-TOKEN']").attr("content"),
                },
            });
            let data = await response.json();
        }
    }



    $("section").addClass("wow fadeInUp");
    $("section").addClass("wow fadeInUp");
    $("section").addClass("data-wow-delay", ".5s");

    $("#why-choose-us even ul li").addClass("data-wow-delay", ".5s");
    $("#why-choose-us even ul li").addClass("data-wow-duration", "1s");
    $("#why-choose-us even ul li:nth-child(odd)").addClass("wow slideInRIght");
    $("#why-choose-us even ul li:nth-child(even)").addClass("wow slideInLeft");

    new WOW.WOW().init();
});
