$(function () {
    $(".dropdown .dropdown-label").on("click", function () {
        let $self = $(this);

        $(this).parent().toggleClass("active");
        $("body").on("click", function () {
            if ($self.hasClass("active")) $Self.removeClass("active");
        });
    });
    $(".dropdown .dropdown-label").on("mouseenter", function () {
        let $self = $(this);

        $(this).parent().toggleClass("active");
        $(this).on("mouseleave", function () {
            if ($self.hasClass("active")) $Self.removeClass("active");
        });
    });

    $(".dropdown .dropdown-content ").on("change", function () {
        if ($(this).hasClass("active")) $(this).removeClass("active");
    });
});
