$(async function () {
    const preload = (await import("./animations/preload")).default;

    await preload();

    const navTimeline = gsap.timeline({});
    const itemTimeline = gsap.timeline({});
    const formOrderTimeline = gsap.timeline({});
    const formReviewTimeline = gsap.timeline({});
    const pricingFeatureTimeline = gsap.timeline({});

    navTimeline
        .from("#navigation li", {
            scaleY: 0,
            stagger: 0.2,
        })
        .to("#navigation li", {
            scaleY: 1,
            duration: 0.1,
            ease: "power2.inOut",
        });

    pricingFeatureTimeline
        .from("#pricing li", {
            x: "-100%",
            opacity: 0,
            stagger: 0.1,
        })
        .to("#pricings li", {
            x: 0,
            opacity: 1,
            duration: 0.25,
        });
});
