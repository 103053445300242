$(function () {
    $(".carousel").ready(function () {
        let index = 0;
        let total = $(".carousel .item").length - 1;

        function changeCarousel() {
            $(".indicators button").addClass("bg-sublight");
            $(".indicators button").addClass("dark:bg-subdark");
            $(".indicators button").eq(index).removeClass("bg-sublight");
            $(".indicators button").eq(index).removeClass("dark:bg-subdark");
            $(".indicators button").eq(index).addClass("bg-secondary");

            $(".carousel .item").css("width", 0);
            $(`.carousel .item`).eq(index).css("width", "100%");
        }
        changeCarousel();
        let interval = setInterval(function () {
            if (index + 1 > total) index = 0;
            else index += 1;

            changeCarousel();
        }, 2500);

        $(".carousel .btn-previous").on("click", function () {
            if (index - 1 < 0) index = total;
            else index -= 1;

            changeCarousel();
        });

        $(".carousel .btn-next").on("click", function () {
            if (index + 1 > total) index = 0;
            else index += 1;

            changeCarousel();
        });
    });
});
