$(function () {
    const $form = $("#search-field form");
    const $input = $("#search-field #search");

    $input.on("keyup", async function () {
        let formTarget = new URL($form.attr("action"));
        formTarget.searchParams.append($(this).attr("name"), $(this).val());

        let response = await fetch(formTarget);
        let html = await response.text();
        let $page = $(html);
        let items = $("#items", $page).parent();
        let pageTitle = $("title", $page).text();

        console.log(pageTitle);
        document.title = pageTitle;
        window.history.pushState({ html: html, pageTitle: pageTitle }, "", formTarget);

        $("#items").parent().replaceWith(items);
    });
});
